@mixin darkTheme() {
  --background: hsl(240deg 5% 19%);
  --text: #ddd;
  --inverseText: #45454c;
  --bodyText: #ffffffbb;
  --mutedText: #ffffff55;
  --subtle: #ffffff17;
  --panelBack: #2d333a;
  --panelHead: #45454c;
  --greyHover: var(--muted);
  --primaryText: hsl(256, 92%, 85%);
  --muted: #aaa;
  --selected: var(--muted);
  --success: #1dd1a1;
  --warning: #feca57;
  --danger: #f55238;
  --optional: var(--warning);
  --notAvailable: hsl(8deg 40% 54% / 45%);
  --available: hsl(164deg 51% 46% / 89%);

  --mapPrimary: hsl(192, 76%, 40%);
  --mapBack: var(--panelBack);
  --mapDark: #3f464a;
  --mapLight: hsl(193, 23%, 22%);
  --mapFilled: #474750;
  --mapShadow: #000000;
  --mapOutline: var(--mapPrimary);
  --mapText: var(--text);

  --primary: hsl(192, 62%, 72%);
  --primary100: hsl(192, 62%, 52%);
  --primary200: hsl(192, 62%, 32%);
  --outline: var(--primary);
  --cool: #25a9e066;
  --warmer: #f6921e66;
  --warmest: #ee403666;
  --good: #3d864766;
  --notActive: transparent;
}
@mixin lightTheme() {
  --background: hsl(0, 0%, 95%);
  --text: #45454c;
  --inverseText: #45454c;
  --bodyText: #45454cbb;
  --mutedText: #45454c8f;
  --subtle: #00000033;
  --panelBack: #f0f0f0;
  --panelHead: #a9a9b1a1;
  --greyHover: var(--panelHead);
  --muted: #888;
  --selected: var(--panelHead);
  --success: #1dd1a1;
  --warning: #feca57;
  --danger: #f55238;
  --optional: var(--warning);
  --notAvailable: hsl(8deg 100% 85% / 45%);
  --available: hsl(164deg 71% 63% / 89%);

  --mapPrimary: hsl(192, 76%, 23%);
  --mapBack: var(--panelBack);
  --mapDark: #d0d2d3;
  --mapLight: hsl(193, 23%, 73%);
  --mapFilled: var(--background);
  --mapOutline: var(--mapPrimary);
  --mapShadow: rgb(108, 123, 127);
  --mapText: var(--text);

  --primary: hsl(192, 76%, 23%);
  --primaryText: hsl(192, 92%, 55%);
  --primary100: hsl(192, 66%, 43%);
  --primary200: hsl(192, 56%, 63%);
  --outline: var(--primary);
  --cool: #c5edf7;
  --warmer: #fee5a4;
  --not-good: var(--warmer);
  --warmest: #ffb05b;
  --good: #6adf7a;
  --notActive: transparent;
}
