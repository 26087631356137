@import './variables';

:root {
  color-scheme: light dark;
  supported-color-schemes: light dark;
  @include lightTheme();
}
// :root,
// :root.light {
//   // Default is light theme
// }
// :root {
//   // But user can also have dark theme
//   @media (prefers-color-scheme: dark) {
//     @include darkTheme();
//   }
//   &.dark {
//     @include darkTheme();
//   }
// }

html {
}

body {
  margin: 0;
  font-family: 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);
  background: var(--background);
  font-size: clamp(0.2rem, 0.9vmax, 1rem);
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
* {
  box-sizing: border-box;
}
router-outlet {
  height: 0;
}

svg.highcharts-root {
  font-family: 'Cantarell', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: var(--primary);
}
.mat-icon {
  stroke: currentColor;
}

.highcharts-container {
  border-radius: 0.5em;
}

lib-map #labels text {
  // For some reason, text labels displayed in officemap appear larger than
  // labels displayed in the editor. This is a workaround to make them appear
  // the same size.
  font-size: 10.5pt;
}
